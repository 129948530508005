.page-header-container {
  display: flex;
  justify-content: center;

  top: 0;
  left: 0;
  right: 0;

  background: var(--blue);
  height: 150px;

  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.page-header-text {
  color: white;
  font-size: 36px;

  align-self: flex-end;
  padding-bottom: 20px;
}

@media(max-width: 600px) {
  .page-header-text {
    font-size: 28px;

    padding-bottom: 15px;
  }

  .page-header-container {
    height: 100px;
  }
}
