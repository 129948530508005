html,
body,
#root {
  height: inherit;
}

body {
  --red: #f50357;
  --light-blue: #3f51b5bd;
  --blue: #3f51b5;
  --faded-blue: #3f51b57d;
  --translucent-blue: #3f51b51f;
  --light-gray: #eeeeee;
  --gray: #9e9e9e;
  --dark-gray: rgba(0, 0, 0, 0.54);

  margin: 0;
  font-family: Roboto;
  background: var(--light-gray);
}

.inherit-height {
  height: inherit;
}

.none {
  display: none;
}

.page-max-width {
  width: 100%;
  max-width: 1200px;

  margin: 0 auto;
}

.link-text {
  color: var(--blue);
  text-decoration: underline;

  width: fit-content;
  cursor: pointer;
}
