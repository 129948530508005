.dialog-title-container {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
}

.dialog-title-text {
  color: var(--blue);
  font-size: 24px;
}

.dialog-body-container {
  display: flex;
  flex-direction: column;

  padding: 0 25px 25px 25px;
}

.dialog-close-icon {
  color: var(--red);

  cursor: pointer;
}
