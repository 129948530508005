.emergency-drug-create-button-container {
  padding: 50px 0;

  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}

.dosage-function-text {
  color: var(--dark-gray);
  font-size: 16px;
}

.select-language-create {
  padding: 16px 0 0 0;
}