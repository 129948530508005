.la-toxicity {
  box-sizing: border-box;
  margin-bottom: 16px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto 32px auto;
}

.la-toxicity-form-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.la-toxicity-form-input label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 8px;
}

.la-toxicity-form-input select {
  font-size: 18px;
  font-weight: 500;
  max-width: 640px;
}
