.blue-header-container {
  display: flex;
  flex-direction: row;
  background: var(--blue);

  height: 68px;
  margin-bottom: 15px;
}

.blue-header-container-text {
  font-size: 24px;
  color: #ffffff;

  margin: auto 0;
  padding-left: 25px;
}

.drawer-close-button {
  color: #ffffff;
  padding: 10px 0 10px 20px;
}

.drawer-item {
  display: flex;
  font-size: 20px;
  padding: 15px 35px 15px 25px;

  cursor: pointer;
}

.drawer-item-active {
  background: var(--blue);
  color: #ffffff;
}

.drawer-item-icon {
  padding-right: 20px;
}
