.crisis-sheet-diagram-container {
  width: 100%;
  height: 600px;

  margin: 25px 0 40px 0;

  border-radius: 3px;
  border: 3px solid var(--blue);
  background: var(--translucent-blue);
}

@media(max-width: 600px) {
  .crisis-sheet-diagram-container {
    height: 400px;
    margin: 20px 0 0 0;
  }
}
