.json-schema-editor-container {
}

.json-schema-editor-container #root__title {
  font-size: 20px;
  padding-bottom: 5px;
  margin-bottom: 10px;

  color: var(--blue);

  width: 100%;
}

.json-schema-editor-container fieldset {
  border: none;
  padding: 0;
}

.json-schema-editor-instructions {
  display: flex;
  flex-direction: column;

  padding-left: 25px;

  padding-bottom: 5px;
  margin-bottom: 15px;
  width: 100%;
  border-bottom: 1px solid lightgrey;
}

.json-schema-editor-instructions-description {
  display: flex;
  flex-direction: column;
}

.json-schema-editor-instructions-description label {
  font-size: 12px;
  padding-bottom: 10px;
  color: grey;
}

.json-schema-editor-instructions-description p {
  font-size: 14px;
  padding: 5px 0 15px 0;
  margin: 0;

  color: grey;
}

.json-schema-editor-instructions-description textarea {
  font-size: 16px;
  padding: 5px 0;

  outline: none;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid var(--blue);
}

.json-schema-editor-instructions-linked-id {
  display: flex;
  flex-direction: column;
}

.json-schema-editor-instructions-linked-id fieldset legend {
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  width: 100%;

  color: var(--blue);
}

.json-schema-editor-instructions-linked-id p {
  font-size: 14px;
  padding: 5px 0 15px 0;
  margin: 0;

  color: grey;
}

.json-schema-editor-instructions-linked-id select {
  font-size: 16px;
  width: calc(100% - 100px);

  margin-bottom: 20px;

  background: none;
  border: 2px solid var(--faded-blue);
  outline: none;
}

.array-item {
  display: flex;
  flex-direction: row;
}

.array-item .col-xs-9 {
  width: 100%;
  padding-right: 10px;
}

.array-item-toolbox {
  height: fit-content;
  position: absolute;
  right: 0;
  padding-right: 10px;
}

.json-schema-editor-icon {
  font-size: 25px;
}

.btn {
  border: none;
  outline: none;
  background: none;

  color: var(--light-blue);
}

.btn.btn-danger {
  color: var(--red);
}

.btn.btn-add {
  color: var(--light-blue);
}

.btn:disabled {
  color: var(--gray);
}

.array-item-add {
  padding: 0 0 5px 0 !important;
  margin: 0 0 15px 0;
}
