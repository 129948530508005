.page-title-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;

  padding: 50px 25px;

  color: var(--blue);
}

.page-title-text {
  font-size: 36px;

  padding-left: 15px;
}

.page-title-button-container {
  padding-top: 0;
  padding-left: 50px;
}

@media(max-width: 600px) {
  .page-title-container {
    flex-direction: column;
  }

  .page-title-text {
    font-size: 28px;
    padding-left: 0;
  }

  .hide-when-small {
    display: none;
  }

  .page-title-button-container {
    padding-left: 0;
    padding-top: 25px;
  }
}
