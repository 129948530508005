.crisis-sheet-edit-node-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding-top: 25px;
}

.crisis-sheet-edit-button-responsive-style {
  width: fit-content;
}

.crisis-sheet-edit-button-responsive-spacing {
  margin: none;
}

@media(max-width: 600px) {
  .crisis-sheet-edit-node-button-container {
    flex-direction: column;
  }

  .crisis-sheet-edit-button-responsive-style {
    margin: 25px 0;
    width: 100%;
  }

  .crisis-sheet-edit-button-responsive-spacing {
    margin-top: 25px;
  }
}

.crisis-sheet-edit-button-responsive-style button {
  margin-bottom: 25px;
}

.crisis-sheet-edit-decision-container {
}

.crisis-sheet-edit-decision-title {
  color: var(--dark-gray);
  font-size: 18px;

  line-height: 2.5;
}

.crisis-sheet-edit-decision-text {
  font-size: 18px;
}

.crisis-sheet-edit-json-schema-container {
  padding-top: 20px;
}
