.toolbar-title-text {
  color: white;
  font-size: 24px;

  padding: 20px 0 20px 20px;
  cursor: default;
}

.toolbar-max-width {
  width: 100%;
  max-width: 1200px;

  align-self: center;
}

@media(max-width: 600px) {
  .toolbar-title-text {
    font-size: 20px;
  }
}
