.login-page-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 40px;
}

.login-button-responsive-style {
  margin-bottom: 0px;
}

@media(max-width: 600px) {
  .login-page-button-container {
    flex-direction: column;
  }

  .login-button-responsive-style {
    margin-bottom: 25px !important;
  }
}
