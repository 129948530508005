.emergency-drugs-table-width {
  max-width: 800px;
  margin: 0 auto;
}

@media(max-width: 600px) {
  .emergency-drugs-table-width td {
    max-width: 100px;
  }
  .emergency-drugs-table-width th {
    max-width: 100px;
  }
}
