.floating-form-container {
  margin: 50px auto;

  border-radius: 5px;
  padding: 5px 25px 0 25px;

  background: #FFFFFF;
  box-shadow: 0 1px 15px 1px rgba(0,0,0,0.2);

  width: 80%;
  max-width: 400px;
}

.floating-form-container h2 {
  font-size: 36px;
  color: var(--blue);

  margin-bottom: 10px;
  text-align: center;
}

.floating-form {
  padding: 0 20px 35px 20px;
}

@media(max-width: 600px) {
  .floating-form-container h2 {
    font-size: 28px;
  }
}
