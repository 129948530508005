.confirm-dialog-buttons {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}

.confirm-dialog-text {
  font-size: 18px;
  color: var(--gray);
  padding: 15px 0 25px;
}
