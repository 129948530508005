.crisis-sheet-table-width {
  max-width: 1000px;
  margin: 0 auto;
}

.select-language{
  padding: 15px;
}

@media(max-width: 600px) {
  .crisis-sheet-table-width td {
    max-width: 100px;
  }
  .crisis-sheet-table-width th {
    max-width: 100px;
  }
}
