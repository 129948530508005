.auth-code-table-width {
  max-width: 800px;
  margin: 0 auto;
}

@media(max-width: 600px) {
  .auth-code-table-width td {
    max-width: 100px;
  }
  .auth-code-table-width th {
    max-width: 100px;
  }
}
