.auth-code-view-container {
  display: flex;
  flex-direction: column;

  padding-top: 20px;
}

.auth-code-view-title {
  color: var(--dark-gray);
  font-size: 20px;

  padding-bottom: 10px;
}

.auth-code-view-text {
  font-size: 20px;
}

.auth-code-view-button {
  padding-top: 25px;
}
